var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _vm.loading
        ? _c("ck-loader")
        : [
            _c("vue-headful", {
              attrs: {
                title:
                  _vm.appName +
                  " - Edit Collection Persona: " +
                  _vm.collection.name
              }
            }),
            _c(
              "gov-back-link",
              { attrs: { to: { name: "admin-index-collections-personas" } } },
              [_vm._v("Back to collection personas")]
            ),
            _c(
              "gov-main-wrapper",
              [
                _c(
                  "gov-grid-row",
                  [
                    _c(
                      "gov-grid-column",
                      { attrs: { width: "one-half" } },
                      [
                        _c(
                          "gov-heading",
                          { attrs: { size: "xl" } },
                          [
                            _c("gov-caption", { attrs: { size: "xl" } }, [
                              _vm._v("Collections")
                            ]),
                            _vm._v("\n            Personas\n          ")
                          ],
                          1
                        ),
                        _c("gov-heading", { attrs: { size: "m" } }, [
                          _vm._v("Edit persona")
                        ]),
                        _c("gov-body", [
                          _vm._v(
                            "\n            From this page, you can edit the persona groups that appear on the\n            homepage. You can change which taxonomies they refer to, the icon\n            used, and the information provided in the description and sidebox.\n          "
                          )
                        ]),
                        _c("collection-form", {
                          attrs: {
                            errors: _vm.form.$errors,
                            id: _vm.collection.id,
                            "show-edit-slug": true,
                            slug: _vm.form.slug,
                            name: _vm.form.name,
                            subtitle: _vm.form.subtitle,
                            intro: _vm.form.intro,
                            order: _vm.form.order,
                            enabled: _vm.form.enabled,
                            homepage: _vm.form.homepage,
                            sideboxes: _vm.form.sideboxes,
                            category_taxonomies: _vm.form.category_taxonomies,
                            image_file_id: _vm.form.image_file_id
                          },
                          on: {
                            "update:slug": function($event) {
                              return _vm.$set(_vm.form, "slug", $event)
                            },
                            "update:name": function($event) {
                              return _vm.$set(_vm.form, "name", $event)
                            },
                            "update:subtitle": function($event) {
                              return _vm.$set(_vm.form, "subtitle", $event)
                            },
                            "update:intro": function($event) {
                              return _vm.$set(_vm.form, "intro", $event)
                            },
                            "update:order": function($event) {
                              return _vm.$set(_vm.form, "order", $event)
                            },
                            "update:enabled": function($event) {
                              return _vm.$set(_vm.form, "enabled", $event)
                            },
                            "update:homepage": function($event) {
                              return _vm.$set(_vm.form, "homepage", $event)
                            },
                            "update:sideboxes": function($event) {
                              return _vm.$set(_vm.form, "sideboxes", $event)
                            },
                            "update:category_taxonomies": function($event) {
                              return _vm.$set(
                                _vm.form,
                                "category_taxonomies",
                                $event
                              )
                            },
                            "update:image_file_id": function($event) {
                              return _vm.$set(_vm.form, "image_file_id", $event)
                            },
                            clear: function($event) {
                              return _vm.form.$errors.clear($event)
                            },
                            "image-changed": function($event) {
                              _vm.imageChanged = $event
                            },
                            "alt-text-changed": function($event) {
                              _vm.altTextChanged = true
                            }
                          }
                        }),
                        _vm.form.$submitting
                          ? _c(
                              "gov-button",
                              { attrs: { disabled: "", type: "submit" } },
                              [_vm._v("Updating...")]
                            )
                          : _c(
                              "gov-button",
                              {
                                attrs: { type: "submit" },
                                on: { click: _vm.onSubmit }
                              },
                              [_vm._v("Update")]
                            ),
                        _vm.form.$errors.any()
                          ? _c("ck-submit-error")
                          : _vm._e(),
                        _c("gov-section-break", { attrs: { size: "l" } }),
                        _c("ck-delete-button", {
                          attrs: {
                            resource: "persona",
                            endpoint:
                              "/collections/personas/" + this.collection.id
                          },
                          on: { deleted: _vm.onDelete }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }